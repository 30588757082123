


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const StatististicsStore = namespace('StatististicsStore')


import Chart, { ChartSeriesInterface } from '@/components/Chart.vue'

export const MONTHS: string[] = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

export const PERIOD = 6

@Component({
  name: 'StatisticsCharts',
  components: { Chart },
})
export default class StatisticsCharts extends Vue {
  @Prop({ default: false })
  readonly panel!: boolean

  @StatististicsStore.Getter
  public statististicsList!: any

  @StatististicsStore.Getter
  public bannerStatististicsList!: any

  get months(): string[] {
    const month = new Date().getMonth() + 1
    const original = [...MONTHS]
    const difference = month - PERIOD
    const start = difference < 0 ? 0 : difference
    const months = original.slice(start, month)

    if (difference < 0) {
      months.unshift(...original.slice(original.length - Math.abs(difference)))
    }

    return months
  }

  get banners() {
    return this.bannerStatististicsList
  }

  get phoneViewsSeries(): ChartSeriesInterface[] {
    return [
      {
        color: '#8840a7',
        name: 'Показы телефонов',
        data: this.statististicsList.phone_views,
        total: this.statististicsList.phone_views_total,
      },
    ]
  }

  get placeViewsSeries(): ChartSeriesInterface[] {
    return [
      {
        color: '#ff2d00',
        name: 'Просмотры',
        data: this.statististicsList.place_views,
        total: this.statististicsList.place_views_total,
      },
    ]
  }

  get phoneCallsSeries(): ChartSeriesInterface[] {
    return [
      {
        color: '#00d2a1',
        name: 'Звонки всего',
        data: this.statististicsList.phone_calls,
        total: this.statististicsList.phone_calls_total,
      },
      {
        color: '#ff2d00',
        name: 'Пропущенные звонки',
        data: this.statististicsList.phone_calls_missed,
        total: this.statististicsList.phone_calls_missed_total,
      },
    ]
  }

  get whatsappSeries(): ChartSeriesInterface[] {
    return [
      {
        color: '#00d2a1',
        name: 'Whatsapp всего',
        data: this.statististicsList.whatsapp_views,
        total: this.statististicsList.whatsapp_views_total,
      },
    ]
  }

  get telegramSeries(): ChartSeriesInterface[] {
    return [
      {
        color: '#00d2a1',
        name: 'telegram всего',
        data: this.statististicsList.telegram_views,
        total: this.statististicsList.telegram_views_total,
      },
    ]
  }

  get callbacksSeries(): ChartSeriesInterface[] {
    return [
      {
        color: '#2b61d2',
        name: 'Заявки',
        data: this.statististicsList.callbacks,
        total: this.statististicsList.callbacks_total,
      },
    ]
  }
}
